/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.7.1/lightgallery.umd.min.js
 * - /npm/lightgallery@2.7.1/plugins/fullscreen/lg-fullscreen.min.js
 * - /npm/lightgallery@2.7.1/plugins/video/lg-video.min.js
 * - /npm/lightgallery@2.7.1/plugins/autoplay/lg-autoplay.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
